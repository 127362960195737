<template>
    <div class="post-feed">
      <div :class="'post-card ' + getClass(index)" v-for="(post, index) in posts" :key="post.id">
        <router-link :to="`/blogs/${post.route}`">
          <div :class="'inner ' + getClass(index)">
            <div :class="'post-content ' + getClass(index)">
              <p>{{ post.topic + " |  " + post.author + ' | ' + formatDate(post.createdAt)}}</p>
            
              <h1 :class="'post-title ' + getClass(index)">{{ post.title }}</h1>
           
            </div>
            <div :class="'card-banner ' + getClass(index)">
              <img :class="'post-img ' + getClass(index)" v-lazy="require(`@/assets/blogs/${post.bannerUrl}`)">
            </div>
         </div>
        </router-link>
      </div>
    </div>
</template>
  <script>
  
  import { mapState } from 'vuex';

  export default {
    name: 'BlogPage',
      computed: {
        ...mapState(['posts'])
      }, 
      created() {
        this.$store.dispatch('fetchBlogPosts');
      },
      methods: {
        getClass(index) {
          let chunk = index %6;

          if(chunk < 3) {
            return 'first-three';
          } else if (chunk < 5) {
            return 'next-two';
          } else {
            return 'last-one';
          }
        },
        formatDate(str) {
          let date = new Date(str);
          return date.toISOString().split('T')[0]; // returns "2023-06-29"
        }
      }
  }
  </script>
  
  <style>
.post-feed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 12%;
  align-items: flex-start;
}

.post-card {
  box-sizing: border-box;
  padding: 20px;
  /* display: flex; */
  
}

.post-card.first-three {
  flex: 1 0 33.33%; 
  box-sizing: border-box;
  flex-direction: column;
}

.post-card.next-two {
  flex: 1 0 50%; 
  box-sizing: border-box;
}

.post-card.last-one {
  flex: 1 0 100%; 
  box-sizing: border-box;
  flex-direction: row;
}

.post-title {
  order: 1;
  font-size: 23px;
  font-weight: lighter;
  border-top: 1px solid black; 
  padding-top: 10px;
}


.post-content.last-one {
  /* height: 50%; */
  /* flex: 1; */
  margin-bottom: 0px;
  width: 30%;
  /* height: 300px; */
  margin-right: 30px;
}

.post-img {
  order: 2;
  width: 100%; 
  object-fit: cover;
}

.post-img.first-three{
  height: 300px;
}

.post-img.next-two{
  height: 350px;
}

.post-img.last-one{
  /* flex: 1; */
  height : 30vh;
}

.inner {
  display: flex;
  background-color: #f3ede871;
  padding: 20px;
  align-items: center;
  /* border:1px solid black; */
}

.first-three {
  flex-direction: column;
}

.next-two {
  flex-direction: column;
}

.card-banner.last-one{
  margin-bottom: 0px;
  width: 70%;
}

@media (max-width: 430px) {
 
  /* .post-card.last-one {
    flex-direction: column;
  } */

  .inner.last-one{
    flex-direction: column;
  }
  .post-img.last-one{
    height: 200px;
  }

  .post-img.first-three{
    height: 200px;
  }

  .post-img.next-two{
    height: 200px;
  }

}  

@media (max-width: 640px) {
  .post-feed {
    margin: 0 3%;
  }
  .post-card.first-three {
    flex: 1 0 100%; 
  }
  .card-banner.last-one{
    width: 100%;
  }
  .post-content.last-one {
    width: 99%;
  }
}  
</style>