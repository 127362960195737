<template>
    <div class="home-page-container"> 
        <!-- <div class="ticker">
            <div class="ticker-text"> 
                {{ repeatedTickerText }}
            </div>
        </div> -->
        <MainBanner />
        <!-- <SignUpBanner /> -->
        <BrandFocusBanner/>
        <div id="about"><AboutUs/></div>
        <TopOffers />
        <!-- <SmallChanges /> -->
        <div id="partner"><LetsWorkTogether /></div>
        <!-- <ContactPage /> -->
    </div>
</template>

<script>
// import EmailSignUp from '../components/EmailSignUp.vue'
import MainBanner from '../components/MainBanner.vue'
import AboutUs from '../components/AboutUs.vue'
import LetsWorkTogether from '../components/LetsWorkTogether.vue'
import TopOffers from '../components/TopOffers.vue'
import BrandFocusBanner from '../components/BrandFocusBanner.vue'
// import SignUpBanner from '../components/SignUpBanner.vue'
// import ContactPage from './ContactPage.vue'

export default {
    name: 'HomePage',
    components: {
        MainBanner,
        // EmailSignUp,
        BrandFocusBanner,
        AboutUs,
        LetsWorkTogether,
        TopOffers,
        // SignUpBanner
        // ContactPage
    },
    data() {
     return {
        tickerText: " support local brands + get exlusive deals " ,
        repeatCount: 2
     }   
    },
    computed: {
        repeatedTickerText() {
            return this.repeatString(this.tickerText, this.repeatCount)
        }
    },
    methods: {
        repeatString(str, num) {
            return str.repeat(num)
        }
    }
}
</script>
<style scoped>

.home-page-container {
    margin: 0px;
    padding: 0px;
    
}


.ticker {
  background-color: transparent;
  position: absolute;
  width: 100%;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  animation: ticker 20s linear infinite;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

</style>
