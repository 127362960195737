<template>
    <div class="work-tgt-container">
        <div class="work-tgt-inner-container">
            <h2 id="work-tgt-header">Work Together</h2>
            <p id="work-tgt-content"> 
                Get in touch to become a brand partner
            </p>
            <router-link to="/contact">
                <button click="redirect" type="redirect" id="redirect-button">Get in touch</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LetsWorkTogether'
}
</script>

<style>
.work-tgt-container {
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 0px 150px 0px;

    font-size : 20px;
}
.work-tgt-inner-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#work-tgt-header {
    margin-bottom: 10px;
    font-weight: normal;
    font-style: bold;
}

#work-tgt-content {
    font-size: 14px;
    margin-top: 0px;
    font-weight: lighter;
    margin-left: 2px;
}

#redirect-button {
    justify-content: center;
    background-color: white;
    color: black;
    padding: 10px 10px 10px 10px;
    font: 16px;
    font-family: 'Poppins', sans-serif;
    border: 0.2px solid #cbc9c9;
    font-size: 12px;
    font-weight: 320;
    margin-top: 0;
    border-radius: 20px;
    transition: box-shadow 0.2s ease-int-out;
    flex-shrink: 0;
    margin-top: 15px;
    width: 200px;
    cursor: pointer;

}

#redirect-button:hover {
    transform: scale(1.05);
    background-color: whitesmoke;
}

@media (max-width: 640px) {

    .work-tgt-header {
        text-align: center;
    }

    .work-tgt-inner-container {
        align-items: center;
        margin-left: 50px;
    }

    .worrk-tgt-content {
        text-align: center;
    }

}
</style>