/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import ContactPage from './views/ContactPage.vue';
import BlogPage from './views/BlogPage.vue';
import OfferPage from './views/OfferPage.vue';
import SpecialOfferPage from './views/SpecialOfferPage.vue';
import CategoryPage from './views/CategoryPage.vue';
import BlogPost from './components/BlogPost.vue'


const routes = [
  { path: '/', component: HomePage, meta: {title:'Collectiv© | Bespoke deals with hidden brands', description: 'This is home page of our site', keywords: 'home, main'}},
  { path: '/contact', component: ContactPage, meta: {title:'Contact Collectiv', description: 'This is Contact page of our site', keywords: 'contact, email, address'} },
  { path: '/offers/:cat', component: CategoryPage, props: true, meta: {title:'Offer Page for ', description: 'This is an individual offer page for ', keywords: 'coupons, deals, discount, company, offersd'} },
  { path: '/brands/:route', component: OfferPage, props: true, meta: {title:'Brand Page for ', description: 'This is an individual brand page for ', keywords: 'coupons, deals, discount, company, brand'} },
  { path: '/event/:event', component: SpecialOfferPage, props: true, meta: {title:'Special Offers Page for ', description: 'This is an individual special offer page for ', keywords: 'specials, events, seasonal offers, discounts'}},
  // { path: '/cats/:cat', component: CategoryPage, props: true, meta: {title:'Individual Category Page for ', description: 'This is an individual category page for ', keywords: 'discounts, category, coupons, small brands' }},
  { path: '/blogs', component: BlogPage, props: true, meta: {title:'Blog Page', description: 'This is a blog page', keywords: 'discounts, blogs, coupons' }},
  { path: '/blogs/:route', component: BlogPost, props: true, meta: {title:'Blog Post', description: 'This is a blog post', keywords: 'discounts, blogs, coupons' }},
];


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
          return {
            el: to.hash,
            behavior: 'smooth',
          }
        } else if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 }
        }
      }
});

router.beforeEach((to, from, next) => {

  // Dynamic Title based on route parameters
  if (to.meta.title) {
      let title = to.meta.title;

      // Loop through all parameters and append them to the title
      for (let paramName in to.params) {
          if (to.params.hasOwnProperty(paramName)) {
              title += ' ' + to.params[paramName];
          }
      }

      document.title = title;
      console.log('Document title set to:', document.title);
  }

  // Dynamic Description based on route parameters
  if (to.meta.description) {
    let description = to.meta.description;

    // Loop through all parameters and append them to the description
    for (let paramName in to.params) {
        if (to.params.hasOwnProperty(paramName)) {
            description += ' ' + to.params[paramName];
        }
    }

    let meta = document.querySelector('meta[name="description"]');
    if (meta) {
        meta.setAttribute("content", description);
        console.log('Meta description updated to:', description);
    } else {
        let metaTag = document.createElement("meta");
        metaTag.name = "description";
        metaTag.content = description;
        document.head.appendChild(metaTag);
        console.log('Meta description added:', description);
    }
}

  // Keywords meta tag
  if (to.meta.keywords) {
      let meta = document.querySelector('meta[name="keywords"]');
      if (meta) {
          meta.setAttribute("content", to.meta.keywords);
          console.log('Meta keywords updated to:', to.meta.keywords);
      } else {
          let metaTag = document.createElement("meta");
          metaTag.name = "keywords";
          metaTag.content = to.meta.keywords;
          document.head.appendChild(metaTag);
          console.log('Meta keywords added:', to.meta.keywords);
      }
  }
  next();
});

export default router;
