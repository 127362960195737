<template>
 
  <div class="top-offers-header">
      <h3 class="top-offers-title">Favourite Offers</h3>
      <p class="top-offers-sub"> </p>
  </div>
  <div class="grid-container">
    <div class="grid">
      <div v-for="(topOffer) in topOffers" :key="topOffer.id" class="box">
        <div class="image">
          <MqResponsive target="['sm-']">
            <img width="100px" class="img-fit" v-lazy="getBannerImage(topOffer.bannerUrl)" :src="topOffer.bannerUrl" :alt="topOffer.name + ' Banner'">
          </MqResponsive>
        </div>
        <div class="offer-container">
          <div class="brand-container">
            <img class="brand-logo" :src="require(`@/assets/brands/${topOffer.logoUrl}`)" :alt="topOffer.name + ' Logo'">
            <div class="content-container">
              <h3>{{ topOffer.name }}</h3>
              <p>{{ parseDescription(topOffer.description) }}</p>
            </div>
          </div>
          <div class="button-container">
              <a class="black-button" @click="openTwoLinks(topOffer)" target="_blank" rel="noopener">Get Offer</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { MqResponsive } from "vue3-mq";
export default {
  components: {
    MqResponsive,
  },
  data() {
    return {
      items: [
        {
          brandId: "1",
          image: "beaufort-and-blake-banner-summer.jpeg",
          logo: "beaufort-and-blake-logo.jpeg",
          brand: "Beaufort and Blake",
          route: "beaufort-and-blake",
          link: "https://www.beaufortandblake.com",
          description: "15% off orders over £100",
          about: "British lifestyle brand. Focuses on timeless pieces, blending British heritage with contemporary aesthetics"
        },
        {
          brandId: "2",
          image: "banton-frame-works-banner-model.png",
          logo: "banton-frame-works-logo.jpeg",
          route: "banton-frame-works",
          link: "https://www.bantonframeworks.co.uk/",
          brand: "Banton Frameworks",
          description: "Click to find out"
        },
        {
          brandId: "3",
          image: "frederique-constant-banner-watch.jpeg",
          logo: "frederique-constant-logo.png",
          route: "frederique-constant",
          link: "https://frederiqueconstant.com/",
          brand: "Frederique Constant",
          description: "Click to find out"
        },
        {
          brandId: "4",
          image: "oas-banner-dress.jpeg",
          logo: "oas-logo.png",
          route: "oas-company",
          link: "https://oascompany.com/gb",
          brand: "OAS Company",
          description: "20% off & free shipping"
        },
        {
          brandId: "5",
          image: "dusk-banner-bed.jpeg",
          logo: "dusk-logo.jpeg",
          link: "https://dusk.com",
          route: "dusk",
          brand: "Dusk",
          description: "10% off orders & Up to 50%"
        },
        {
          brandId: "6",
          image: "astrid-and-miyu-banner-earrings.png",
          logo: "astrid-and-miyu-logo.jpeg",
          route: "astrid-and-miyu",
          link: "https://www.astridandmiyu.com/",
          brand: "Astrid & Miyu",
          description: "10% off orders"
        },
      ]
    };
  },
  computed: {
        ...mapState(['topOffers'])
    }, 
  created() {
    console.log(this.$isMobile())
    this.$store.dispatch('fetchTopOffers');
  },
  methods: {
    getBannerImage(bannerUrl) {
        
        return require(`@/assets/brands/${bannerUrl}`);
    },
    async getImagePath(image) {
      const imagePath = require(`@/assets/brands/${image}`);
      return imagePath;
    },
    getImageSrcset(bannerUrl) {
      const image = new Image();
      image.onload = () => {
          const imageWidth = image.width;
          return `${image.src} ${imageWidth}w`;
      };
      image.src = require(`@/assets/brands/${bannerUrl}`);
    },
    openTwoLinks(topOffer) {
        window.open(`/brands/${topOffer.route}`);
        let link = '';
          if (topOffer.affiliateLink) {
            link = topOffer.affiliateLink;
          } else {
            link = topOffer.link;
          }
        setTimeout(() => {
          window.location.href = link;
        }, 50);
        window.location.href = link;
      },
    parseDescription(text) {
        let endPosition = text.indexOf('.');
        if (endPosition !== -1) {
        return text.substring(0, endPosition + 1); // adding 1 to include the full stop
        }
        return text; // returns original text if there's no full stop
      }
    }
};
</script>

<style scoped>
.grid-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

}
.offer-container {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:10px;
}

.content-container {
  margin-top: 5px;
}

.top-offers-header {
  margin:60px 0px 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.top-offers-title {
  font-size: x-large;
  
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  
}


.box {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

.content {
  padding: 10px;
}

h3 {
  margin: 0;
  font-size: 18px;
}

p {
  margin-top: 0px;
  font-size: 14px;
}

.img-fit {
  /* width: 100%;
  height: 300px;
  object-fit: cover;
  max-width: 100%; */
}

.black-button {
  background-color: black;
  white-space: nowrap;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 0;
  border-radius: 20px;
  font-size:  13px;
  font-weight: normal;
  margin-right: 10px;
}
.brand-logo {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.brand-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5em;
}

.button-container {
  flex-shrink: 0; /* Prevent button from shrinking */
  margin-bottom: 10px;
}

@media (max-width: 640px) {
    .grid {
      grid-template-columns: repeat(2, 2fr);
    }

    .offer-container {
        flex-direction: column;
        height: 100%;
    }

    .black-button {
        padding: 12% 75%;
    }

    .button-container {
        margin-right: 55%;
    }

    /* .content-description {
        font-size: 12px;
    } */
  }
</style>
