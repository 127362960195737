<template>
    <div class = "home-container collapse-flex gap-flex">
            <div class = "home-heading-container header-flex">
                <a href="#">
                    <h1>Hidden deals for cool ones</h1>
                </a>    
                <h2>discover trending brands</h2>
                <h3 id="sm-banner">Join to access exclusive offers</h3>
                <EmailSignUp/>
            </div>
            <div class = "home-image"> 
                <img class= "img-flex" id="img-banner" v-lazy="require('@/assets/ecobag.jpg')" alt="collectiv main image"> 
            </div>
        </div>
</template>
<script>
import EmailSignUp from '../components/EmailSignUp.vue'
export default {
    name: 'MainBanner',
    components: {
        EmailSignUp
    }
}
</script>
<style scoped>

.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
}

.home-heading-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 50px;
    /* padding: 0px 20px 0px 20px; */
}



#img-banner {
    filter: sepia(40%) hue-rotate(-10deg) brightness(110%) contrast(70%);
    border-radius: 150px 150px 0 0;
}


h1 {
    margin: 0;

    font-size: 40px;
}

h2 {
    font-weight: normal;
    font-size: 25px;
    margin: 0;
}

h3 {
    font-weight: normal;
    font-size: 15px;
    margin-top: 10px;
}


</style>
