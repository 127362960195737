<template>
    <div class="special-cont">
        <div class="special-intro-cont" :style="{ backgroundImage: `url(${require(`../assets/categories/${imgUrl}`)})`}">
            <!-- <img class="special-img" :src="require(`@/assets/themes/${specialUrl}`)" :alt="special + ' Banner'"> -->
            <div class="cat-des-cont">
                <h1 class="cat-name">
                    {{ catName }}
                </h1>
                <p class="cat-des">{{ description }}</p>
            </div>
        </div>
    </div>
    <div class="special-offers-wrap">
        <div  v-for="offer in offers" :key="offer.id">
            <div class="special-offers-cont" v-if="offer && offer.brand">
                <div class="special-logo-wrap">
                    <img class="brand-logo" v-lazy="require('@/assets/brands/' + offer.brand.logoUrl)" :alt="offer.brand.name + ' Logo'">
                </div>
                <div class="brand-name-wrap">
                    <a class="brand-name-link" @click="openTwoLinks(offer.brand)" target="_blank" rel="noopener">
                        {{ offer.brand.name }}
                    </a>
                </div>
                <div class="special-content-wrap">
                    <h3>{{ offer.title }}</h3>
                    <p class="brand-offer-des">{{ offer.description }}</p>
                </div>
                <div class="special-button-wrap">
                <button class="special-button" type="button" @click="openPopup(offer, offer.brand.link, offer.brand.logoUrl)" >Get Code</button>
                <div v-if="openedPopupId == offer.id">
                    <offer-modal :visible="openedPopupId == offer.id" :offer="selectedOffer" :link="link" :logoUrl="logoUrl" @close="closePopup"></offer-modal>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>``
  
  <script>
  import { reactive, onMounted } from 'vue';
  import OfferModal from '../components/OfferModal.vue'; // adjust the path as necessary
  
  import axios from 'axios'

  export default {
        name: 'CategoryPage',
        props: {
            cat: {
                type: String,
                required: true
            }
        },
        components: {
            OfferModal,
        },
        data() {
            return {
                isModalVisible: false,
                selectedOffer: null,
                link: null,
                logoUrl: null,
                openedPopupId: null,
            };
        },
        setup(props) {
            // Determine the logo URL based on the 'special' prop<li class="dropdown-item"><a href="/cats/home">Home</a></li>
        let catName, gender, categoryName, type, imgUrl, description;
        switch (props.cat) {
            case 'womens-fashion':
                gender="WOMEN";
                categoryName="FASHION";
                catName = "Women's Clothes";
                imgUrl = 'womens-fashion-banner-bag.jpg';
                description = "Hand picked selection of brands featuring timeless classics to more trendy pieces";
                break;
            case 'mens-fashion':
                gender="MEN";
                categoryName="FASHION";
                catName = "Men's fashion";
                imgUrl = 'mens-fashion-banner.jpg';
                description = "Curated selection of men's fashion brands";
                break;
            case 'womens-jewellery':
                gender="WOMEN";
                categoryName="ACCESSORIES";
                type="JEWELLERY";
                catName = "Women's Jewellery";
                imgUrl = 'womens-fashion-banner-bag.jpg';
                description = "Hand picked selection of brands featuring timeless classics to more trendy pieces";
                break;
            case 'watches':
                categoryName="ACCESSORIES";
                type="WATCHES";
                catName = "Watches";
                imgUrl = 'mens-fashion-banner.jpg';
                description = "Curated selection of men's fashion brands";
                break;
            case 'glasses':
                categoryName="ACCESSORIES";
                type="GLASSES";
                catName = "Glasses & Sunglasses";
                imgUrl = 'eye-wear-banner.jpg';
                description = "Bespoke frames, selected brands meticulously crafting the perfect fit and style in glasses and sunglasses";
                break;
            case 'productivity':
                categoryName="PRODUCTIVITY";
                catName = "Productivity";
                imgUrl = 'productivity-banner.jpg';
                description = "Unlock your full potential with cutting-edge productivity tools, engineered to streamline tasks and foster efficiency";
                break;
            case 'home':
                categoryName="HOME";
                catName = "Homeware";
                imgUrl = 'home-banner.jpg';
                description = "High quality, investment pieces. Thoughtfully designed to blend functionality and style";
                break;
            default:
                categoryName="FASHION";
                catName = "Our Offers";
                imgUrl = 'productivity-banner.jpg';
                description = "Explore our offers by category";
        }

        const offers = reactive([]);
        
        const fetchOffers = async () => {
            try {
                const backendUrl = process.env.VUE_APP_BACKEND_URL;
                // Create a params object
                let params = {
                    category: categoryName
                }

                // Check if gender is present and if it is, add it to the params
                if (gender) {
                    params.gender = gender;
                }

                if (type) {
                    params.type = type;
                }

                const response = await axios.get(`${backendUrl}/offers`, { params });
                
                // Storing the offers array in the reactive offers data property
                offers.push(...response.data.content);
                console.log(offers);

            } catch (error) {
                console.error(error);
            }
        };

        onMounted(fetchOffers);

        return {
            offers,
            imgUrl,
            description,
            gender,
            categoryName,
            catName,
            type
        };
    },
    methods: {
        openTwoLinks(brand) {
        window.open(`/brands/${brand.route}`);
        let link = '';
          if (brand.affiliateLink) {
            link = brand.affiliateLink;
          } else {
            link = brand.link;
          }
        setTimeout(() => {
          window.location.href = link;
        }, 50);
        window.location.href = link;
      },
        async fetchData() {
            try {
                const backendUrl = process.env.VUE_APP_BACKEND_URL;
                const route = this.brand;
                const response = await axios.get(`${backendUrl}/brands/${route}`);
                console.log(response);
            } catch (error) {
                console.error(error);
                throw new Error('Failed to fetch');
            }
        },
         openPopup(offer, link, logoUrl) {
            this.selectedOffer = offer;
            this.openedPopupId = offer.id;
            this.link = link;
            this.logoUrl = logoUrl;
        }, closePopup() {
            this.openedPopupId = null;
        },
    }
  }
  </script>
  
  <style>

  .special-cont {
    display: flex;
    flex-direction: row;
  }

  .special-intro-cont {
    color: white;
    /* background-image: url('../assets/rattan.jpg');  */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center 60%;
  }

  .cat-des-cont {
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    width: 20%; 
  }

  .cat-name {
    margin-bottom: 0;
    text-align: center;
  }

  .cat-des {
    text-align: center;
    font-size: 13px;
  }

  /* coupon style */

  /* coupon style */
    .special-offers-wrap {
        display: flex;
        flex-direction: column;
        margin: 20px 5% 100px 5%;
    }

    .special-offers-cont {
    background-color: white;
    /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 10px 10px 20px;
    gap: 1em;
    }

    /* .brand-offers-cont > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    } */

    .special-offers-cont .brand-logo {
        align-content: center;
    }

    .special-offers-cont h3 {
    font-size: 17px;
    font-weight: normal;
    color: #333;
    width: 100%;
    margin-bottom: 3px;
    }

    .special-offer-des {
    font-size: 13px;
    color: #333;
    width: 100%;
    margin-top: 0px;
    max-width: 330px;
    max-height: 100%;
    margin-bottom: 30px;
    }

    .special-button  {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 11px;
    text-align: center;
    width: 100%;
    /* transition: background-color 0.3s ease; */
    }

    @media screen and (min-width: 640px) {
    /* .special-name {
        margin: 10% 28% 8px 9%;
    } */

    /* .special-des {
        margin: 0 28% 0 9%;
        font-size: 15
    } */

    .brand-name-link {
        cursor: pointer;
    }

  .special-offers-wrap {
    margin: 20px 10% 100px 10%;
  }

  .special-offers-cont {
    display: flex;
    flex-direction: row;
    margin: 0.5% 27%;
    gap: 2.2em;
    /* min-width: 57%; */
  }

    .special-content-wrap {
        width: 50%;
    }

    .brand-name-wrap {
        width: 10%;
    }
    }

    /* @media screen and (min-width: 350px) {
        .special-offers-cont {
            margin: 2% 5%;
        }
    } */
    
  @media screen and (max-width: 680px) {
        .cat-des-cont {
            width: 80%;;
            padding: 30px;
        }
        
    }
  </style>``