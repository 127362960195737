<template>
    <div class="special-cont">
        <div class="special-intro-cont" :style="{ backgroundImage: `url(${require(`../assets/themes/${specialUrl}`)})`}">
            <!-- <img class="special-img" :src="require(`@/assets/themes/${specialUrl}`)" :alt="special + ' Banner'"> -->
            <h1 class="special-name">{{ specialName }}</h1>
            <p class="special-des">{{ description }}</p>
        </div>
    </div>
    <div class="special-offers-wrap">
        <offers-block :offers="offers"></offers-block>
        <!-- <div  v-for="offer in offers" :key="offer.id">
            <div class="offer-container" v-if="offer && offer.brand">
                <div class="special-logo-wrap">
                    <img class="brand-logo" v-lazy="require('@/assets/brands/' + offer.brand.logoUrl)" :alt="offer.brand.name + ' Logo'">
                </div>
                <div class="event-brand-name">{{ offer.brand.name }}</div>
                <div class="event-content-wrap">
                    <h3>{{ offer.title }}</h3>
                    <p class="brand-offer-des">{{ offer.description }}</p>
                </div>
                <div class="special-button-wrap">
                <button class="special-button" type="button" @click="openPopup(offer, offer.brand.link, offer.brand.logoUrl)" >Get Code</button>
                <div v-if="openedPopupId == offer.id">
                    <offer-modal :visible="openedPopupId == offer.id" :offer="selectedOffer" :link="link" :logoUrl="logoUrl" @close="closePopup"></offer-modal>
                </div>
            </div>
            </div>
        </div> -->
    </div>
</template>
  
  <script>
  import { reactive, onMounted } from 'vue';
  import OffersBlock from '../components/OffersBlock.vue'; // adjust the path as necessary
  
  import axios from 'axios'

  export default {
        name: 'SpecialOfferPage',
        props: {
            event: {
                type: String,
                required: true
            }
        },
        components: {
            OffersBlock,
        },
        data() {
            return {
                isModalVisible: false,
                selectedOffer: null,
                link: null,
                logoUrl: null,
                openedPopupId: null,
            };
        },
        setup(props) {
            // Determine the logo URL based on the 'special' prop
        let specialName, specialUrl, description;
        switch (props.event) {
            // case 'summer':
            //     specialName = "Summer is Here";
            //     specialUrl = 'summer-is-here-2.jpg';
            //     description = "Summer is here";
            //     break;
            // case 'cool-ai-tools':
            //     specialName = "Cool AI Tools";
            //     specialUrl = 'cool-ai-tools.jpg';
            //     description = "Cool AI tools";
            //     break;
            case 'top-offers':
                specialName = "Top Offers";
                specialUrl = 'top-offers-1.jpg';
                description = "Top Offers";
                break;
            // default:
            //     specialName = "Our Offers";
            //     specialUrl = 'summer-is-here-1.png';
            //     description = "Explore our offers";
        }

        const offers = reactive([]);
        
        const fetchOffers = async () => {
            try {
                const backendUrl = process.env.VUE_APP_BACKEND_URL;
                const response = await axios.get(`${backendUrl}/offers`, {
                    params: {
                        event: props.event.replace(/-/g, '_').toUpperCase()
                    }
                });

                // Storing the offers array in the reactive offers data property
                offers.push(...response.data.content);``

                console.log("offers passed to offer block :" + offers.size());

            } catch (error) {
                console.error(error);
            }
        };
        
        
        

        onMounted(fetchOffers);

        return {
            offers,
            specialUrl,
            description,
            specialName
        };
    },
    methods: {
        async fetchData() {
            try {
                const backendUrl = process.env.VUE_APP_BACKEND_URL;
                const route = this.brand;
                const response = await axios.get(`${backendUrl}/brands/${route}`);
                console.log(response);
            } catch (error) {
                console.error(error);
                throw new Error('Failed to fetch');
            }
        },
         openPopup(offer, link, logoUrl) {
            this.selectedOffer = offer;
            this.openedPopupId = offer.id;
            this.link = link;
            this.logoUrl = logoUrl;
        }, closePopup() {
            this.openedPopupId = null;
        },
    }
  }
  </script>
  
  <style>

  .special-cont {
    display: flex;
    flex-direction: row;
  }

  .special-intro-cont {
    color: white;
    /* background-image: url('../assets/rattan.jpg');  */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center 70%;
  }

  /* coupon style */

  /* coupon style */
    .special-offers-wrap {
        display: flex;
        flex-direction: column;
        margin: 20px 5% 100px 5%;
    }

    .event-offer-container {
    background-color: white;
    /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 15%;
    gap: 0.4em;
    text-align: center;
    }

    /* .brand-offers-cont > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    } */

    .event-offer-container .brand-logo {
        align-content: center;
    }

    .event-offer-container h3 {
    font-size: 17px;
    font-weight: normal;
    color: #333;
    width: 100%;
    margin-bottom: 3px;
    }

    .special-offer-des {
    font-size: 13px;
    color: #333;
    width: 100%;
    margin-top: 0px;
    max-width: 330px;
    max-height: 100%;
    margin-bottom: 30px;
    }

    .special-button  {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 11px;
    text-align: center;
    width: 100%;
    /* transition: background-color 0.3s ease; */
    }

    @media screen and (min-width: 640px) {
    /* .special-name {
        margin: 10% 28% 8px 9%;
    } */

    /* .special-des {
        margin: 0 28% 0 9%;
        font-size: 15px;
    } */

  .special-offers-wrap {
    margin: 0 30%;
  }

  .offer-container {
    display: flex;
    flex-direction: row;
    margin: 50px 20%;
    
  }

  .event-content-wrap {
    min-width: 35%;
  }

  .event-brand-name {
    min-width: 10%;
    word-wrap: break-word;
    align-items: left;
  }

 }
  </style>