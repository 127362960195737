<template>
    <form @submit="submitSignUp">
        <!-- <p class="signup-prompt">coming soon, <br> join our waiting list in the meantime :)</p> -->
        <div class="email-container">
            <input type="email" id="email-input" placeholder="Email Address" v-model="email" autocomplete="email">
            <button click="submitSignUp" type="submit" id="email-button">Sign Up</button>
        </div>
        <!-- <button
            data-tf-popup="BMjFEEU8"
            data-tf-opacity="100"
            data-tf-size="100"
            data-tf-iframe-props="title=Classification Quiz for Lead Generation Template"
            data-tf-transitive-search-params
            data-tf-medium="snippet"
            style="all:unset;font-family:sans-serif;display:inline-block;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-color:#000;color:#fff;font-size:16px;border-radius:25px;padding:0 33px;font-weight:lighter;height:50px;cursor:pointer;line-height:50px;text-align:center;margin:0;text-decoration:none;"
            @click.prevent="openTypeform"
        >
        Become a member
        </button> -->
        <div v-if="signUpSuccess" id="signup-success">{{ signUpSuccess }} </div>
        <div v-if="signUpError" id="signup-error">{{ signUpError }}</div>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    name: 'EmailSignUp',
    data() {
        return {
            email: '',
            signUpSuccess: '',
            signUpError: ''
        }
    },
    methods: {
        async submitSignUp() {
            const backendUrl = process.env.VUE_APP_BACKEND_URL;

            console.log(this.email);
            event.preventDefault();

            try {
                const response = await axios.post(
                `${backendUrl}/signup`,
                {
                    email: this.email,
                },
                {
                    withCredentials: true, // Include credentials in the request
                }
                );
                this.signUpError = ''; 
                this.signUpSuccess = "Welcome Onboard !";
                console.log(response);
            } catch (error) {
                this.signUpSuccess = ''; 
                this.signUpError = "Oh no, something went wrong :(";
                console.error(error);
            }
        },
        openTypeform() {
            const script = document.createElement('script');
            script.src = '//embed.typeform.com/next/embed.js';
            script.async = true;
            script.onload = () => {
                window.typeformEmbed.makePopup(
                'https://e8mf5gy2ovz.typeform.com/to/BMjFEEU8',
                {
                    mode: 'popup',
                    autoOpen: true,
                    autoClose: 3000,
                    hideHeaders: true,
                    hideFooter: true,
                    hideScrollIndicator: true,
                    buttonText: 'Try me!',
                    onSubmit: function () {
                    console.log('Typeform submitted');
                    },
                }
                );
            };
            document.body.appendChild(script);
        }
    }
}
</script>

<style>

.signup-prompt {
    color: rgb(110, 109, 109);
    font-size: 13px;
    margin-bottom: 9px;
    margin-left: 10px;
}
.email-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
    gap: 0.2em;
    
}

#email-input {
    background-color: #fff;
    color: #2e2d2d;
    padding: 10px 55px 10px 15px;
    /* margin-right: 10px; */
    font: 16px;
    font-family: 'Poppins', sans-serif;
    border: 0.5px solid #cbc9c9;
    font-size: 14px;
    font-weight: 320;
    width: 13.2em;
    margin-top: 0;
    border-radius: 20px;
    transition: box-shadow 0.2s ease-int-out;
    flex-shrink: 0;
}

#email-input::placeholder {
    color: #7a7a7a;
}

#email-input:hover {
    /* border: 2px solid black; */
    box-shadow: 0 0 2px 1px rgb(143, 143, 143);
    flex-shrink: 0;
}

#email-input:focus {
    outline: none;
    flex-shrink: 0;

}

#email-button {
    cursor: pointer;
    border: none;
    background-color: #000;
    padding: 13px 20px 13px 20px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    margin-top: 0;
    font-weight: 320;
    font-size: 11px;
    letter-spacing: 0.3px;
    border-radius: 20px;
    flex-shrink: 0;
}

#email-button:hover {
    background-color: rgb(36, 35, 35);
    flex-shrink: 0;
}


#email-button:active {
    background-color: rgb(36, 35, 35);
    flex-shrink: 0;
}

#signup-success {
    color: black;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-style: wavy;
    padding : 5px;
    margin: 10px;
    font-size: 12px;
    font-weight: bold;
}

#signup-error {
    color: rgb(252, 30, 30);
    /* background-color: rgba(255, 97, 97, 0.596); */
    text-decoration: underline;
    text-underline-offset: 5px;
    padding : 5px;
    margin: 10px;
    font-size: 11px;
}


</style>