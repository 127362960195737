<template>
    <div class="brand-offers-cont" v-for="offer in offers" :key="offer.id">
            <div class="brand-logo-wrap">
                <!-- <p>{{ offer.brand }}</p>
                <div v-if="offer.brand">
                    <p>{{ offer.brand.name }}</p>
                    <p>{{ offer.brand.logoUrl }}</p>
                </div> -->
                <img class="brand-logo" :src="require('@/assets/brands/' + offer.brand.logoUrl)" :alt="' Logo'">
            </div>
            <div class="brand-content-wrap">
                <h3>{{ offer.title }}</h3>
                <p class="brand-offer-des">{{ offer.description }}</p>
            </div>
            <div class="brand-button-wrap">
                <button class="brand-button" type="button" @click="openPopup(offer, offer.link, offer.logoUrl)" >Get Code</button>
                <div v-if="openedPopupId == offer.id">
                    <offer-modal :visible="openedPopupId == offer.id" :offer="offer" :link="offer.link" :logoUrl="offer.logoUrl" @close="closePopup"></offer-modal>
                </div>
          </div>
      </div>
</template>
<script>

export default {
    name: "OfferBlock",
    props: {
        offers: {
            type: Array,
            required: true
        },
    }


};
</script>
<style >

  /* coupon style */
  .brand-offers-wrap {
    display: flex;
    flex-direction: column;
    margin: 20px 5% 100px 5%;
  }

.brand-offers-cont {
  background-color: white;
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 10px 10px 10px 20px;
  gap: 1em;
}

.brand-offers-cont .brand-logo {
    align-content: center;
}

.brand-offers-cont h3 {
  font-size: 17px;
  font-weight: normal;
  color: #333;
  width: 100%;
  margin-bottom: 3px;
}

.brand-offer-des {
  font-size: 13px;
  color: #333;
  width: 100%;
  margin-top: 0px;
  max-width: 330px;
  max-height: 100%;
  margin-bottom: 30px;
}

.brand-button  {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  width: 100%;
  /* transition: background-color 0.3s ease; */
}
@media screen and (min-width: 640px) {
    .brand-name {
        margin: 10% 28% 8px 9%;
    }

    .brand-des {
        margin: 0 28% 0 9%;
        font-size: 15px;
  }

  .brand-content-wrap {
    width: 50%;
  }

  .brand-offers-wrap {
    margin: 20px 25% 100px 25%;
  }

  .brand-offers-cont {
    display: flex;
    flex-direction: row;

  }
 }

 @media screen and (max-width: 1050px) { 
    .brand-offers-wrap {
    margin: 20px 5% 100px 5%;
  }
}

</style>
