// store.js
import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    posts: [],
    brandDuJours:[],
    topOffers: [],
  },
  mutations: {

    SET_POSTS(state, data) { // mutation to set the posts
        state.posts = data
      },
    SET_BRAND_DU_JOURS(state, data) { // mutation to set AI brands for showcase on homepage
      state.brandDuJours = data
    },
    SET_TOP_OFFERS(state, data) { // mutation to set AI brands for showcase on homepage
      state.topOffers = data
    }
  },
  actions: {

    async fetchBlogPosts({ commit }) {
      try {
          const backendUrl = process.env.VUE_APP_BACKEND_URL;
          const response = await axios.get(`${backendUrl}/blogs`, {
            params: {
              size: 12
            }
          });
          commit('SET_POSTS', response.data.content)
          console.log("posts are successfully set")
          console.table(response.data.content);
      } catch (error) {
          console.error("error fetching the posts:", error);
      }
    },
      async fetchAIBrands({ commit }) {
        try {
            const backendUrl = process.env.VUE_APP_BACKEND_URL;
            const response = await axios.get(`${backendUrl}/brands`, {
              params: {
                size: 4,
                special: "COOL_AI_TOOLS",
              }
            });
            commit('SET_AI_BRANDS', response.data.content)
            console.log("AI brands are successfully set")
        } catch (error) {
            console.error("error fetching the AI brands:", error);
        }
      },async fetchBrandDuJours({ commit }) {
        try {
            const backendUrl = process.env.VUE_APP_BACKEND_URL;
            const response = await axios.get(`${backendUrl}/brands`, {
              params: {
                size: 4,
                event: "BRAND_DU_JOUR",
              }
            });
            commit('SET_BRAND_DU_JOURS', response.data.content)
            console.log("Brand du jours are successfully set")
        } catch (error) {
            console.error("error fetching the brand du jours:", error);
        }
      },
      async fetchTopOffers({ commit }) {
        try {
            const backendUrl = process.env.VUE_APP_BACKEND_URL;
            const response = await axios.get(`${backendUrl}/brands`, {
              params: {
                size: 6,
                event: "TOP_OFFERS",
              }
            });
            commit('SET_TOP_OFFERS', response.data.content)
            console.log("Top offers are successfully set")
        } catch (error) {
            console.error("error fetching the top offers :", error);
        }
      },
  },
  modules: {
  }
})
