<template>
    <div class="about-container collapse-flex reverse-flex">
        <div class="about-content accordion-flex">
            <div id="about-accordion">
                <div v-for="(item, index) in items" :key="index" class="accordion-item">
                    <div class="accordion-header" @click="toggleAccordion(index)">
                        <div>{{ item.header }}</div>
                        <img v-if="item.isExpanded" id="arrow-expanded" class ="accordion-arrow" v-lazy="require('@/assets/arrow.png')">
                        <img v-else id="arrow-closed" class="accordion-arrow" v-lazy="require('@/assets/arrow.png')">
                    </div>
                    <div v-if="item.isExpanded" v-html="item.content" class="accordion-content"></div>
                </div>
             </div>
        </div>
        <div id="about-intro">
            Explore hot brands <br>
            Discover products you love <br>
            Access the best deals <br> 
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';

export default {
    name: 'AboutUs',
    data() {
        return {
            contentHeight: 0
        }
    },
    setup() {
        const items = reactive([
            {
                header: 'Discover the Undiscovered',
                content: 'We help you explore cool businesses that you haven’t discovered yet without you having to do the leg work',
                isExpanded: false
            },
            {
                header: 'Be Original',
                content: 'We know you have good tase and smaller brands often offer unique, one-of-a-kind products that are not available at larger chain stores',
                isExpanded: false
            },
            {
                header: 'Smart Choices',
                content: "We research and partner with brands with good quality products that will last longer<br> <br>It is sometimes hard making informed purchasing decisions based on the values and the ethics due to lack of transparency. <br> <br> We also help provide the information in one place to help you make informed choices so that you can make informed purchasing decision",
                isExpanded: false
            },
            {
                header: 'Deal Guarantee',
                content: 'Building close relationships with the brands allows us to get you the best deals on great products <br><br> We review our discount codes regularly so that you can always count on us. Get in touch with us if this is not the case and we will get back to you asap',
                isExpanded: false
            },
            {
                header: 'Grow Together',
                content: 'We believe that helping others helps us <br> We link smaller brands with you so that both can benefit<br> <br> Smaller brands also often have smaller carbon footprint and are more environmentally conscious than other corporations contributing to a better future for our planet',
                isExpanded: false
            }
        ]);
        function toggleAccordion(index) {
            items.forEach((item, i) => {
                if (i !== index) {
                item.isExpanded = false;
                }
            });
            items[index].isExpanded = !items[index].isExpanded;
            this.contentHeight = this.$refs.content.scrollHeight;
        }
        return {
            items,
            toggleAccordion
        };
    }
};
</script>

<style>

.about-container {
    display: flex;
    white-space: normal;
    width: 100%;
    /* background: linear-gradient(to bottom, white, lightgrey 40%, darkgrey 60%, black); */
    background: linear-gradient(to top, white, rgb(219, 219, 219) 40%,  rgb(239, 237, 237), white);
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 15vh;

}

#about-intro {
    color: black;
    font-size: 20px;
    font-weight: lighter;
    max-width: 60%;
    margin-top: 15vh;
}

.about-content {
    margin-top: 15vh;
    min-width: 200px;
}


.about-accordion {
    border : 1px solid black;
}

.accordion-item {
    border-bottom: 1px solid black;
    
}

.accordion-header {
    padding: 10px;
    cursor: pointer;
    font-weight: lighter;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.accordion-content {
    padding: 10px;
    /* word-wrap: break-word; */
    font-weight: lighter;
    overflow: hidden;
    transition: height 0.5s ease-out;
    height: contentHeight + 'px';
}

.accordion-arrow {
    width: 15px;
    height: 15px;
    transform: rotate(-90deg)
}


#arrow-expanded {
    transform: rotate(90deg);
}


</style>