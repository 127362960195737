import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'
import App from './App.vue'
import router from './router';
import VueGtag from 'vue-gtag-next';
import { createMetaManager } from 'vue-meta'
import store from './store'
import { Vue3Mq } from "vue3-mq";
import VueMobileDetection from "vue-mobile-detection";


const app = createApp(App);

app.use(VueMobileDetection);

app.use(VueGtag, {
  property: {
    id: "G-JE8B74WP59"
  }
}).use(Vue3Mq, {
  breakpoints: { 
    mobile: 480,
    tablet: 800,
    desktop: Infinity,
  }
})
.use(VueLazyLoad, {
  // preLoad: 1.3,
  // error: 'path/to/error/image.jpg',
  // loading: 'path/to/loading/image.jpg',
  // attempt: 3
}).use(createMetaManager())
   .use(router)
   .use(store)
   .mount('#app');
