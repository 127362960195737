<template>
    <div v-if="visible" class="modal">
        <div class="modal-cont">
            <button class="close-button" @click="closeModal">[ x ]</button>
            <div class="modal-content">
                <div class="brand-logo-wrap">
                    <!-- <p>{{ offer }}</p> -->
                    <img class="brand-logo" v-lazy="require('@/assets/brands/' + logoUrl)" :alt="Logo">
                </div>
                <h2 class="offer-title">{{ offer.title }}</h2>
                <p class="offer-description">{{ offer.description }}</p>
                <div class="code-box">
                    <p class="offer-code">{{ offer.code }}</p>
                    <div v-if="offer.code !== 'No code needed'">
                        <button class="copy-button" :class="{ copied: isCopied }" @click="copyCode">Copy</button>
                    </div>
                </div>
                
                <button class="visit-button" @click="visitSite">Continue to site</button>
            </div>
        </div>
    </div>
    
</template>
<script>

export default {
    name: 'OfferModal',
    props: {
        visible: {
        type: Boolean,
        required: true
        },
        offer: {
        type: Object,
        required: true
        },
        link: {
        type: String,
        required: true
        },
        logoUrl: {
        type: String,
        required: true
        }
    },
    data() {
        return {
            isCopied: false
        }
    },
    setup(props, { emit }) {
        
        const closeModal = () => {
            emit('close');
        }
        
        return {
            closeModal
        }
    }, methods : {
        async copyCode() {
            try {
                await navigator.clipboard.writeText(this.offer.code);
                this.isCopied = true;
                // setTimeout(() => (this.isCopied = false), 20000); // reset
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        visitSite() {
            window.open(this.link, '_blank');
        }
    }
};
</script>
<style>
.modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-cont {
    background-color: #fff;
    /* position:relative; */
    padding: 10px;
    /* margin: 20px; */
    border-radius: 10px;
    max-width: 500px;
    width: 80%;
    
    /* height: 50%; */
}


.modal-content {
    margin: 30px;
    display:flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.close-button {
    /* position: absolute; */
    /* right: 10px; */
    /* top: 10px; */
    font-size: 15px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    margin-left: 90%;
    margin-top: 12px;
}

.offer-title {
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 20px;
}

.offer-description {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 35px;
    width: 90%;
    text-align: center;
}

.code-box {
    border: 1px dotted black;
    background-color: white;
    margin-bottom: 30px;
    /* margin-top: 0px; */
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    
}

.offer-code {
    margin-left: 35%;
}

.copy-button {
    background-color: black;
    color: white;
    border-radius: 17px;
    padding: 11px 25px 11px 25px;
    font-size: 11px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

.copy-button.copied {
    background-color: rgba(102, 102, 102, 0.481);
    color: white;
    border-radius: 17px;
    padding: 11px 25px 11px 25px;
    font-size: 11px;
    border: none;
    margin-right: 10px;
    cursor: default;
}

.visit-button {
    background-color: white;
    color: black;
    border-color: black;
    border: 0.5px solid black;
    padding: 11px 55px 11px 55px;
    font-size: 12px;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .modal-content {
        margin: 2%;
    }

    .close-button {
        margin-left: 80%;
    }

    .offer-code {
        margin-left: 20%;
    }
}
</style>
