<template>
    <div class="brand-intro-cont">
        <div class="brand-img-cont">
            <img class="brand-img" v-lazy="getBannerImage(brandData.bannerUrl)" :alt="brandData.name + ' Banner'">
        </div>
        <div class="brand-des-cont">
            <!-- <img class="brand-logo" :src="require('@/assets/brands/' + brandData.logoUrl)" :alt="brandData.name + ' Logo'"> -->
            <h1 class="brand-name">{{ brandData.name }}</h1>
            <p class="brand-des">{{ brandData.description }}</p>
        </div>
    </div>
    <div class="brand-offers-wrap">
        <div class="brand-offers-cont" v-for="offer in brandData.offers" :key="offer.id">
            <div class="brand-logo-wrap">
                <!-- <p>{{ offer }}</p> -->
                <img class="brand-logo" :src="require('@/assets/brands/' + brandData.logoUrl)" :alt="brandData.name + ' Logo'">
            </div>
            <div class="brand-content-wrap">
                <h3>{{ offer.title }}</h3>
                <p class="brand-offer-des">{{ offer.description }}</p>
            </div>
            <div class="brand-button-wrap">
                <button class="brand-button" type="button" @click="openPopup(offer, brandData.link, brandData.logoUrl)" >Get Code</button>
                <div v-if="openedPopupId == offer.id">
                    <offer-modal :visible="openedPopupId == offer.id" :offer="selectedOffer" :link="link" :logoUrl="logoUrl" @close="closePopup"></offer-modal>
                </div>
          </div>
      </div>
  </div>
</template>
  
  <script>
  import { reactive } from 'vue';
  import OfferModal from '../components/OfferModal.vue'; // adjust the path as necessary
  
  import axios from 'axios'

  export default {
        props: {
            route: {
                type: String,
                required: true
            }
        },
        components: {
            OfferModal,
        },
        data() {
            return {
                isModalVisible: false,
                selectedOffer: null,
                link: null,
                logoUrl: null,
                openedPopupId: null,
            };
        },
        setup(props) {

        const brandData = reactive({
            id: null,
            name: '',
            route: '',
            category: '',
            description: '',
            budget: '',
            link: '',
            affiliateLink: '',
            logoUrl: '',
            bannerUrl: '',
            ranking: null,
            partner: false,
            addedAt: '',
            offers: [],
        });

        (async () => {
        try {
            const backendUrl = process.env.VUE_APP_BACKEND_URL;
            const response = await axios.get(`${backendUrl}/brands/${props.route}`);

            brandData.id = response.data.id;
            brandData.name = response.data.name;
            brandData.route = response.data.route;
            brandData.category = response.data.category;
            brandData.description = response.data.description;
            brandData.budget = response.data.budget;
            brandData.link = response.data.link;
            brandData.affiliateLink = response.data.affiliateLink;
            brandData.logoUrl = response.data.logoUrl;
            brandData.bannerUrl = response.data.bannerUrl;
            brandData.ranking = response.data.ranking;
            brandData.partner = response.data.partner;
            brandData.addedAt = response.data.addedAt;
            brandData.offers = response.data.offers;
            
            console.log(brandData);

        } catch (error) {
            console.error(error);
        }
        })();

        return {
            brandData,
        };
    },
    methods: {
        async fetchData() {
            try {
                const backendUrl = process.env.VUE_APP_BACKEND_URL;
                const route = this.brand;
                const response = await axios.get(`${backendUrl}/brands/${route}`);
                console.log(response);
            } catch (error) {
                console.error(error);
                throw new Error('Failed to fetch brand by route');
            }
        },
        getBannerImage(bannerUrl) {
          return require(`@/assets/brands/${bannerUrl}`);
        },
        getImageSrcset(bannerUrl) {
          const image = new Image();
          image.src = require(`@/assets/brands/${bannerUrl}`);
          const imageWidth = image.width;

          return `${image.src} ${imageWidth}w`;
        },
         openPopup(offer, link, logoUrl) {
            this.selectedOffer = offer;
            this.openedPopupId = offer.id;
            this.link = link;
            this.logoUrl = logoUrl;
        }, closePopup() {
            this.openedPopupId = null;
        },
    }
  }
  </script>
  
  <style>
  .brand-intro-cont {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40%;
    /* margin: 0 auto; */
  }

  .brand-img-cont {
    width: 100%;
    /* border: 0.1px solid #ccc; */
    /* padding: 15px 15px 40px;
    background-color: white;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1); */
  } 

  .brand-img {
    width: 100%;
    height: 100%;
    /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); */
    object-fit: cover;
   }

   .brand-des-cont {
    width: 100%;
    /* margin: 0px 50px 0px; */
    justify-content: space-evenly;
   }

  .brand-logo {
    height: 70px;
    width: 70px;
    border-radius: 70px;
    box-shadow: 2px -4px 6px rgba(0, 0, 0, 0.1);
  }

  .brand-name {
    margin: 10% 28% 8px 9%;
    font-weight: normal;
    font-size: 27px;
  }

  .brand-des {
    margin: 0 10% 0 9%;
    font-size: 15px;
  }

  /* coupon style */
  .brand-offers-wrap {
    display: flex;
    flex-direction: column;
    margin: 20px 5% 100px 5%;
  }

.brand-offers-cont {
  background-color: white;
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 10px 10px 10px 20px;
  gap: 1em;
}

/* .brand-offers-cont > div > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
} */

.brand-offers-cont .brand-logo {
    align-content: center;
}

.brand-offers-cont h3 {
  font-size: 17px;
  font-weight: normal;
  color: #333;
  width: 100%;
  margin-bottom: 3px;
}

.brand-offer-des {
  font-size: 13px;
  color: #333;
  width: 100%;
  margin-top: 0px;
  max-width: 330px;
  max-height: 100%;
  margin-bottom: 30px;
}

.brand-button  {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  width: 100%;
  /* transition: background-color 0.3s ease; */
}

/* .brand-offers-cont button:hover {
  background-color: #0056b3;
} */

@media screen and (min-width: 640px) {
    .brand-name {
        margin: 10% 28% 8px 9%;
    }

    .brand-des {
        margin: 0 28% 0 9%;
        font-size: 15px;
  }

  .brand-content-wrap {
    width: 50%;
  }

  .brand-offers-wrap {
    margin: 20px 25% 100px 25%;
  }

  .brand-offers-cont {
    display: flex;
    flex-direction: row;

  }

 }

 @media screen and (max-width: 1050px) { 
    .brand-offers-wrap {
    margin: 20px 5% 100px 5%;
  }
 }

  </style>