<template>

    <div class="top-offers-header">
        <h3 class="top-offers-title">Brand du jour</h3>
        <p class="top-offers-sub"> </p>
        
        
    </div>
    <div class="grid-container">
        <!-- <p>{{ brandDuJour.name + index }}</p> -->
      <div class="grid">
        <div class="box" v-for="(brandDuJour) in brandDuJours" :key="brandDuJour.id">
            
            <div class="image">
            <img class="img-fit" v-lazy="require(`@/assets/brands/${brandDuJour.bannerUrl}`)" :alt="brandDuJour.name + ' Banner'">
          </div>
          <div class="offer-container">
            <div class="brand-container">
              <img class="brand-logo" v-lazy="require(`@/assets/brands/${brandDuJour.logoUrl}`)" :alt="brandDuJour.name + ' Logo'">
              <div class="content-container">
                <h3 class="content-title">{{ brandDuJour.name }}</h3>
                <p class="content-description">{{ parseDescription(brandDuJour.description) }}</p>
              </div>
            </div>
            <div class="button-container">
                <a class="black-button" @click="openTwoLinks(brandDuJour)" target="_blank" rel="noopener">Go to Brand</a>
            </div>
          </div>
        </div>
        
        <!-- <div v-for="(item, index) in items" :key="index" class="box">
            
          <div class="image">
            <img class="img-fit" :src="require(`@/assets/brands/${item.image}`)" :alt="item.title + ' Banner'">
          </div>
          <div class="offer-container">
            <div class="brand-container">
              <img class="brand-logo" :src="require(`@/assets/brands/${item.logo}`)" :alt="item.title + ' Logo'">
              <div class="content-container">
                <h3>{{ item.brand }}</h3>
                <p>{{ item.description }}</p>
              </div>
            </div>
            <div class="button-container">
                <a class="black-button" @click="openTwoLinks(item)" target="_blank" rel="noopener">Get Offer</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';

  export default {
    name: 'BrandFocusBanner',
    data() {
      return {
          imageSrcsets: {},
      };
    },
    computed: {
        ...mapState(['brandDuJours'])
    }, 
    created() {
      this.$store.dispatch('fetchBrandDuJours');
    },
    watch: {
      brandDuJour: {
        immediate: true,
        deep: true, // It watches the nested properties
        handler(newValue) {
          if (newValue && newValue.bannerUrl) {
            this.loadImageSrcset(newValue.bannerUrl);
          }
        }
      }
    },
    methods: {
      getBannerImage(bannerUrl) {
    try {
        return require(`@/assets/brands/${bannerUrl}`);
    } catch (error) {
        console.error('Failed to load image: ', error);
        // return require('@/assets/brands/default-banner.png');
    }
},
      getImagePath(imageUrl) {
        return require(`@/assets/brands/${imageUrl}`);
      },
      loadImageSrcset(bannerUrl) {
        this.getImageSrcset(bannerUrl).then(srcset => {
          // Store the result in imageSrcsets with bannerUrl as the key
          this.$set(this.imageSrcsets, bannerUrl, srcset);
        });
      },
      getImageSrcset(bannerUrl) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = require(`@/assets/brands/${bannerUrl}`);
          image.onload = function() {
            const imageWidth = image.width;
            resolve(`${image.src} ${imageWidth}w`);
          };
          image.onerror = function() {
            reject(new Error("Failed to load image."));
          };
        });
      },
      getSrcset(bannerUrl) {
        // Return the corresponding srcset or a default value
        return this.imageSrcsets[bannerUrl] || '';
      },
      openTwoLinks(brandDuJour) {
        window.open(`/brands/${brandDuJour.route}`);
        let link = '';
          if (brandDuJour.affiliateLink) {
            link = brandDuJour.affiliateLink;
          } else {
            link = brandDuJour.link;
          }
        setTimeout(() => {
          window.location.href = link;
        }, 50);
        window.location.href = link;
      },
      parseDescription(text) {
        let endPositionDot = text.indexOf('.');
        let endPositionComma = text.indexOf(',');

        // If there's no dot or comma, return the original text
        if (endPositionDot === -1 && endPositionComma === -1) {
            return text;
        }

        // If there's no dot, set the position to the comma
        if (endPositionDot === -1) {
            endPositionDot = endPositionComma;
        }

        // If there's no comma, set the position to the dot
        if (endPositionComma === -1) {
            endPositionComma = endPositionDot;
        }

        // Take the earliest of the two positions
        let endPosition = Math.min(endPositionDot, endPositionComma);

        return text.substring(0, endPosition);

        // let endPosition = text.indexOf('.');
        // if (endPosition !== -1) {
        // return text.substring(0, endPosition + 1); // adding 1 to include the full stop
        // }
        // return text; // returns original text if there's no full stop
      }
    }
  };
  </script>
  
  <style scoped>
  .grid-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .offer-container {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:10px;
  }
  
  .content-container {
    margin-top: 5px;
  }
  
  .top-offers-header {
    margin:60px 0px 10px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .top-offers-title {
    font-size: x-large;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  }
  
  
  
  .box {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
  }
  
  .content {
    padding: 10px;
  }
  
  h3 {
    margin: 0;
    font-size: 18px;
  }
  
  p {
    margin-top: 0px;
    font-size: 14px;
  }
  
  .img-fit {
    width: 100%;
    height: 230px;
    object-fit: cover;
    max-width: 100%;
    object-position: center 85%;
    /* filter: sepia(1); */
  }
  
  .black-button {
    background-color: black;
    white-space: nowrap;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    margin-top: 0;
    border-radius: 20px;
    font-size:  13px;
    font-weight: normal;
  }
  .brand-logo {
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  
  .brand-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
  }
  
  .button-container {
    flex-shrink: 0; /* Prevent button from shrinking */
    margin-bottom: 10px;
  }

  .content-title {
        /* font-size: 12px; */
        /* font-weight: lighter; */
    }
  @media (max-width: 640px) {
    .black-button {
      padding: 13px 19px;
    }
    .grid {
      grid-template-columns: repeat(1, 4fr);
    }

    .offer-container {
        gap: 0em;
        justify-content: space-between;
        margin: 3% 4%;
    }

    .content-description {
        font-size: 12px;
    }


  }
  </style>
  