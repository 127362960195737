<template>
    <nav>
        <div class="nav-container">
            <div class="nav-head-container">
                <router-link to="/">
                    <img id="img-logo" src="../assets/logo-cropped.png" alt="logo" />
                </router-link>
                <!-- <div v-if="$mq === 'mobile'">
                </div> -->
                <!-- <div v-else> -->
                    <li class="nav-item-offer" @mouseover="isExploreVisible = true" @mouseleave="isExploreVisible = false">
                        <p class="nav-title">explore</p>
                        <ul class="dropdown" v-if="isExploreVisible">
                            <!-- <div class="dropdown-left">
                                <li :class="['dropdown-item', 'special-dropdown', 'shift-photo']" :style="{ backgroundImage: `url(${require('../assets/themes/top-offers-1.jpg')})`}">

                                    <a class="special-dropdown-title" href="/special/top-offers" >Top Offers</a>
                                </li>
                                <li :class="['dropdown-item', 'special-dropdown']" :style="{ backgroundImage: `url(${require('../assets/themes/summer-is-here-1.jpg')})`}">
                                    <a class="special-dropdown-title" href="/special/summer" >Summer is Here</a>
                                </li>
                                <li :class="['dropdown-item', 'special-dropdown']" :style="{ backgroundImage: `url(${require('../assets/themes/cool-ai-tools.jpg')})`}">
                                    <a class="special-dropdown-title" href="/special/cool-ai-tools" >Cool AI Tools</a>
                                </li>
                            </div> -->
                            <div class="dropdown-right">
                                <li class="dropdown-item"><a href="/offers/womens-fashion" >Women's Fashion</a></li>
                                    <!-- <ul class="sub-dropdown">
                                        <li class="sub-dropdown-item"><a href="#">Sub-item 1</a></li
                                    </ul> -->
                                <li class="dropdown-item"><a href="/offers/womens-jewellery">Women's Jewellery</a></li>
                                <li class="dropdown-item"><a href="/offers/mens-fashion">Men's Fashion</a></li>
                                <li class="dropdown-item"><a href="/offers/watches">Watches</a></li>
                                <li class="dropdown-item"><a href="/offers/glasses">Glasses & Sunglasses</a></li>
                                <li class="dropdown-item"><a href="/offers/productivity">Productivity Tools</a></li>
                                <li class="dropdown-item"><a href="/offers/home">Home</a></li>
                                <!-- <li class="dropdown-item"><a href="/cats/grocery">Grocery</a></li> -->
                                <!-- <li class="dropdown-item"><a href="/cats/holiday">Holiday</a></li> -->
                                <!-- <li class="dropdown-item"><a href="/cat/gadgets">Gadgets</a></li> -->
                                <!-- <li class="dropdown-item"><a href="/cat/sports">Sports</a></li> -->
                                <!-- <li class="dropdown-item"><a href="/cat/health">Health</a></li> -->
                                <!-- <li class="dropdown-item"><a href="/cats/beauty">Beauty</a></li> -->
                            </div>
                            

                        </ul>
                        
                    </li>
                    <!-- <li class="nav-item-offer" @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
                        <ul class="dropdown" v-if="isDropdownVisible">
                            <div class="dropdown-left">
                                <li :class="['dropdown-item', 'special-dropdown', 'shift-photo']" :style="{ backgroundImage: `url(${require('../assets/themes/top-offers-1.jpg')})`}">

                                    <a class="special-dropdown-title" href="/special/top-offers" >Top Offers</a>
                                </li>
                                <li :class="['dropdown-item', 'special-dropdown']" :style="{ backgroundImage: `url(${require('../assets/themes/summer-is-here-1.jpg')})`}">
                                    <a class="special-dropdown-title" href="/special/summer" >Summer is Here</a>
                                </li>
                                <li :class="['dropdown-item', 'special-dropdown']" :style="{ backgroundImage: `url(${require('../assets/themes/cool-ai-tools.jpg')})`}">
                                    <a class="special-dropdown-title" href="/special/cool-ai-tools" >Cool AI Tools</a>
                                </li>
                            </div>
                        </ul>
                    </li> -->
                    <!-- <li class="nav-item-offer" @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
                        <p class="nav-title">discover</p>
                        <ul class="dropdown" v-if="isDropdownVisible">
                            <div class="dropdown-left">
                                <li :class="['dropdown-item', 'special-dropdown', 'shift-photo']" :style="{ backgroundImage: `url(${require('../assets/themes/top-offers-1.jpg')})`}">

                                    <a class="special-dropdown-title" href="/event/top-offers" >Top Offers</a>
                                </li>
                                <li :class="['dropdown-item', 'special-dropdown']" :style="{ backgroundImage: `url(${require('../assets/themes/summer-is-here-1.jpg')})`}">
                                    <a class="special-dropdown-title" href="/event/summer" >Summer is Here</a>
                                </li>
                                <li :class="['dropdown-item', 'special-dropdown']" :style="{ backgroundImage: `url(${require('../assets/themes/cool-ai-tools.jpg')})`}">
                                    <a class="special-dropdown-title" href="/event/cool-ai-tools" >Cool AI Tools</a>
                                </li>
                            </div>
                        </ul>
                    </li> -->
                </div>

                <!-- <div class="nav-search-container">
                    <input type="text" v-model="query" @input="search" placeholder="Search...">
                    <li>hello</li>
                </div> -->
                <div class="nav-list-container">
                    <li class="nav-title"><router-link to="/blogs">blogs</router-link></li>
                    <li class="nav-title"><router-link to="/contact">contact</router-link></li>
                </div> 
            </div>   
        <!-- </div> -->
    </nav>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            isDropdownVisible: false,
            isExploreVisible: false,
        }
    }
}
</script>

<style>

.nav-container {
    display: flex;
    list-style: column;
    justify-content: space-around;
    align-items: center;
    gap: 20%;
    font-size: 18px;
    margin: 30px;
    /* padding: 26px; */
    /* margin: 0px 20% 0px 0px 20%; */
    list-style: none;
}

#img-logo {
    max-height: 45px;
    max-width: 125px;
    width: auto;
    height: auto;
    clip-path: inset(0);
}

.nav-head-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 2em;
    align-content: center;
    align-items: center;
    /* list-style-type: none; */

}

.nav-list-container {
    display : flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 40%;
}


a{
    text-decoration: none;
    color: black;
}

.nav-item-offer {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /* padding: 15px; */
  /* background-color: #333; */
  /* color: white; */
  
}

.nav-title {
    /* transition: all 0.1s ease-in-out;  */
    /* background-color: transparent; */

}

.nav-title:hover {
    /* filter: blur(1.5px); */
    /* background-color: rgba(211, 211, 211, 0.5); */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness:2.5px;

}


.dropdown {
  /* list-style: none;
  position: absolute;
  background-color: white;
  color: black;
  top: 100%;
  left: 0;
  padding: 10px 0; */
  position: absolute; /* Absolute positioning of dropdown */
  top: 100%; /* Position dropdown below parent item */
  left: 0; /* Align dropdown with left edge of parent item */
  list-style: none; /* Remove default list styling */
  padding: 10px 15px; /* Remove default padding */
  margin: 0; /* Remove default margins */
  background-color: #fff;
  box-shadow: 2px -4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Ensure dropdown is on top of other elements */
  font-size: 15px;
  display: flex;
  margin-block-start: 0;
    margin-block-end: 0;
}

.nav-item:hover .dropdown {
  display: block;
}

.dropdown-item {
  padding: 10px 10px;
  white-space: nowrap;
  
}

.dropdown-item:hover  {
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness:2.5px;
}


.special-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url('../assets/themes/summer-is-here-1.jpg'); */
    /* background-size: cover; */
    background-repeat: no-repeat;
    /* height: 15%; */
    /* width: 89%; */
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.special-dropdown-title {
    color: white;
    margin: 5% 6em 5% 6em;
    font-size: 15px;
} 

.dropdown-left {
    width: 100%;
}

.shift-photo {
    background-position: center 60%;
}


@media screen and (max-width: 640px) {
    .nav-container { 
        gap: 10%;
        margin: 8% 20%;
    }
    .special-dropdown-title {
        margin: 10% 0 10% 0;
    } 
    .nav-list-container {
        gap: 20px;
        margin-right: 10px;
    }

    .nav-head-container {
        gap: 8px;
    }
    .dropdown {
        left: -100px;
    }
}

</style>