<template>
    <div class="bottom-nav-container">
        <div class="bottom-nav-start">
            <div class = "bottom-nav-inner inner-mid">
                <a href="/">
                    <img id="bottom-nav-logo" v-lazy="require('@/assets/logo-cropped.png')" alt="logo" />
                </a>
                <div id="bottom-nav-email">
                    <a href="mailto:hi@collectiv.uk">
                        <img id="bottom-nav-email-icon" v-lazy="require('@/assets/icons/email.png')" alt="email icon">
                    </a>
                    <a id="bottom-nav-email-text" href="mailto:hi@collectiv.uk">hi@collectiv.uk</a>
                </div>
                <div class="bottom-nav-social-container">
                    <a href="https://www.instagram.com/collectivuk/" target="_blank">
                        <img class="bottom-nav-social insta-icon" v-lazy="require('@/assets/icons/instagram.png')" alt="instagram icon">
                    </a>
                    <a  href="https://www.facebook.com/collectivuk" target="_blank">
                        <img class="bottom-nav-social fb-icon" v-lazy="require('@/assets/icons/facebook.png')" alt="facebook icon">
                    </a>
                    <a  href="https://www.tiktok.com/@collectivuk" target="_blank">
                        <img class="bottom-nav-social icon-tiktok" v-lazy="require('@/assets/icons/tiktok.png')" alt="tiktok icon">
                    </a>
                    <a  href="https://www.linkedin.com/company/collectivuk" target="_blank">
                        <img class="bottom-nav-social icon-linkedin" v-lazy="require('@/assets/icons/linkedin.png')"  alt="linkedin icon">
                    </a>``
                </div>
            </div>
            <div class = "bottom-nav-inner inner-end">
                <!-- <div id="bottom-nav-location">London</div> -->
                <div class="bottom-nav-company">Collectiv ltd</div>
                <div class="bottom-nav-address">The Hoxton Mix, <br> 3rd Floor, 86-90 Paul Street, <br> London <br> EC2A 4N3</div>
            </div>
        </div>
        <div class="bottom-nav-end">
            <div class = "bottom-nav-inner inner-mid">
                <div class="bottom-nav-title">Contact</div>
                <div class="bottom-nav-text"><a href="/#partner" >Partner with us</a></div>
                <div class="bottom-nav-text"><a href="/contact" >Support</a></div>
                <div class="bottom-nav-text"><a href="/contact" >Contact Us</a></div>
                <!-- <div id="bottom-nav-email">Careers</div> -->
            </div>
            <div class = "bottom-nav-inner inner-mid">
                <div class="bottom-nav-title">Content</div>
                <div class="bottom-nav-text"><a href="/#about" >About us</a></div>
                <div class="bottom-nav-text"><a href="/blogs" >Blog</a></div>
                <div class="bottom-nav-text"><a href="/contact" >FAQs</a></div>
                <div class="bottom-nav-text"><a href="/contact" >Press</a></div>
            </div>
            
            <!-- <p id="bottom-nav-container"></p> -->
        </div>
    </div>
    <div class="bottom-nav-legal">
        
    </div>
</template>

<script>
export default {
    name: 'BottomNavBar'
}
</script>

<style>

.bottom-nav-container {
    background: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-weight: lighter;
    padding: 5% 5% 5% 5%;
    align-items: flex-start;
}

.bottom-nav-inner {
    display: flex;
    flex-direction: column;
}

.bottom-nav-start{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3em;
    /* flex: 2; */
    /* width: 100%; */
    /* align-content: center; */
    /* justify-content: space-evenly; */
}
.bottom-nav-end{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 5em;
}

.bottom-nav-inner.inner-mid{
    flex: 1;
    white-space: nowrap;
}

.bottom-nav-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.bottom-nav-text {
    font-size: 14px;
    margin: 5px 1px;
}

#bottom-nav-location {
    font-size: 21px;
}

.bottom-nav-company {
    font-size:15px;
    font-weight: bold;
}

.bottom-nav-address {
    font-size:12px;
    font-weight: lighter;
    margin-top: 5px;
}

#bottom-nav-email {
    font-size:15px;
    font-weight: lighter;
    margin-top: 15px;
    align-items:  center;
    display:flex;
    flex-direction: row;
    gap: 0.3em;
    margin-left: 10px;
}

#bottom-nav-email-icon {
    height: 18px;
}

.bottom-nav-social {
    margin-top: 10px;
    height: 40px;
}

#bottom-nav-logo{
    max-height: 45px;
    max-width: 125px;
    width: auto;
    height: auto;
    clip-path: inset(0);
}

@media (max-width: 640px) {
    .bottom-nav-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .bottom-nav-start {
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 0;
    }
    
    .bottom-nav-end {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 1em;
        margin: 5% 0 10% 0;
    }

    #bottom-nav-email-text {
        font-size: 13px;
    }

    #bottom-nav-email-icon {
        height: 15px;
    }

    .bottom-nav-company {
        font-size: 13px;
        white-space: nowrap;
        margin-top: 0;
    }

    .bottom-nav-address {
        font-size: 13px;
        white-space: nowrap;
    }
    .inner-end {
        margin: 10%;
    }

    .bottom-nav-title {
        text-align: center;
        font-size: 15px;
    }

    .bottom-nav-text {
        text-align: center;
        font-size: 15px;
        margin: 2px 1px;
    }


    .bottom-nav-social-container {
        text-align: center;
    }

    #bottom-nav-logo {
        margin-left: 30px;
        height: 20px;
    }

    .bottom-nav-social {
        height: 30px;
    }
}


</style>