<template>
    <div class="blog-post">
        <p>{{ post.topic + " |  " + post.author + ' | ' + formatDate(post.createdAt)}}</p>
        <h1 class="blog-title">{{ post.title }}</h1>
        <img class="blog-img" v-lazy="require(`@/assets/blogs/${post.bannerUrl}`)">
        <div class="blog-content" v-html="post.content"></div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'BlogPost',
  setup(props) {
    const store = useStore()

    // Fetch the post from the store using the id from the route params
    const post = computed(() => store.state.posts.find(post => post.route === props.route))

    // If the posts are not loaded yet, fetch them
    onMounted(() => {
        if (store.state.posts.length === 0) {
            store.dispatch('fetchBlogPosts')
        }
    })

    console.log('post : ' + post.value);
    return { post }
  },
  props: {
    route: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatDate(str) {
        let date = new Date(str);
        return date.toISOString().split('T')[0]; 
    }
  }
}
</script>

<style>
.blog-post {
  margin: 0 17%;
  background-color: #f3ede871;
  padding: 20px;
}

.blog-title {
    font-weight: lighter;
    font-size: 50px;
    border-top: 1px solid black; 
    padding: 30px 30px 0px 30px;
}

.blog-img {
    max-height: 500px;
  order: 2;
  width: 93%; 
  object-fit: cover;
  margin: 0 2%;
}

.blog-content {
    margin: 5% 12%;
}

.blog-content h2 {
    font-weight: lighter;
    font-size: 30px;
}

.blog-content p {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: lighter;
}

@media (max-width: 640px) {
  .blog-post {
    margin: 0 5%;
  }

  .blog-title {
    font-size: 30px;
  }

  .blog-content h2 {
    font-size: 20px;
  }

  .blog-content p {
      font-size: 18px;
  }

}
</style>