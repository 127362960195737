<template>
  
  <NavBar />
  <BottomNavBar />
</template>

<script>
import NavBar from './views/NavBar.vue'
import BottomNavBar from './components/BottomNavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    BottomNavBar
  }
}
</script>

<style>
html, body, #app {
  font-family: 'Poppins', sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.collapse-flex {
    flex-direction: column;
    gap: 0.1em;
}

.accordion-flex {
  width: 70%;
}

.header-flex {
  font-size: 6vw;
}

.img-flex {
  max-width: 10em;
}

.input-flex {
  width: 45vw;
}

@media screen and (min-width: 640px) {
  .collapse-flex {
    flex-direction: row;
  }

  .collapse-flex.reverse-flex {
    flex-direction: row-reverse;
  }

  .gap-flex{
    gap: 4em;
  } 

  .accordion-flex {
    width: 25%;
  }

  .header-flex {
    font-size: 2em;
  }

  .img-flex {
    max-width: 10em;
  }

  .input-flex {
    width: 22vw;
  }
}

</style>
