<template>
  <div class="contact-container collapse-flex reverse-flex gap-flex">
    <div id="contact-right-container" > 
        <!-- <div id="contact-form-container"> -->
          <form @submit.prevent="submitContact">  
              <div id = "contact-blurb">
                <p>Get in touch with us </p>
              </div>
              <div>
                <label for="name">Name *</label> 
              </div>
              <div>
                <input type="text" id="contact-name" class="contact-form-input input-flex" v-model="name" autocomplete="name" required>
              </div>
              <div>
                <label for="name">Company</label> 
              </div>
              <div>
                <input type="text" id="contact-company" class="contact-form-input input-flex"  v-model="company">
              </div>
              <div>
                <label for="email">Email *</label>
              </div>
              <div>
                <input type="email" id="contact-email" class="contact-form-input input-flex" v-model="email" v-on:focus="triggerAutoComplete" autocomplete="email" required>
              </div>
              <div>
                <label for="message">Message *</label>
              </div>
              <div>
                <textarea id="contact-message" class="contact-form-input input-flex" v-model="message" required></textarea>
              </div>
              <button click="submitMessage" type="submit" id="contact-button">Send</button>
            
            <div v-if="contactSuccess" id="contact-success">{{ contactSuccess }} </div>
            <div v-if="contactError" id="contact-error">{{ contactError }}</div>

          
          </form>
        <!-- </div> -->
      </div>
      <div id="contact-left-container">
        <div id="contact-detail-container">
          <h1 id="contact-header"> hi@collectiv.uk </h1>
            <!-- <div id="contact-detail-email">hi@collectiv.uk <br></div> -->
            <div>
                The Hoxton Mix 
            </div>
            <div>
                3rd Floor, 86-90 Paul Street
            </div>
            <div>
                London
            </div>
            <div>
                EC2A 4N3 
            </div>
          
        </div>
      </div>
      

  </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ContactPage',
    data() {
      return {
        name:'',
        company:'',
        email:'',
        message:'',
        contactSuccess:'',
        contactError:''
      }
    },
    methods: {
      submitContact() {
        const backendUrl = process.env.VUE_APP_BACKEND_URL;
        // console.log(this.email);
        console.log(process.env);
        console.log(backendUrl);
        axios.post(`${backendUrl}/contact`, 
            {
              name: this.name,
              company: this.company,
              email: this.email,
              message: this.message
            })
            .then(response => {
                this.contactError = ''
                this.contactSuccess = "Thanks for reaching out !"
                console.log(response)
            })
            .catch(error => {
                this.contactSuccess = ''
                this.contactError = "Oh no :( Something is wrong "
                console.log(error)
            })
      }
    }
}
</script>

<style>

.contact-container {

  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-content: center;
  /* height: 90vh; */
  width: 100%;
  padding: 0px;
  margin-top: 5px;
}

#contact-left-container {
  color: white;
  background-image: url('../assets/rattan.jpg'); 
  background-size: cover;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 150px; */
  padding-top: 50px;
  padding-bottom: 50px;
}

#contact-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    /* padding: 100px 300px 100px 300px; */
    font-weight: lighter;
    flex: 1;
    /* padding: 150px; */
    padding-bottom: 50px;
}

.contact-form-input {
    background-color: #fff;
    color: #2e2d2d;
    padding: 10px 55px 10px 15px;
    font: 16px;
    font-family: 'Poppins', sans-serif;
    border: 0.2px solid #cbc9c9;
    font-size: 12px;
    font-weight: 320;
    margin-top: 0;
    border-radius: 20px;
    transition: box-shadow 0.2s ease-int-out;
    flex-shrink: 0;
    margin-bottom: 15px;
    margin-top: 5px;
}

.contact-form-input:hover {
    /* box-shadow: 0 0 5px 1.5px rgb(93, 93, 93); */
    border-color: black;
    flex-shrink: 0;
}

.contact-form-input:focus {
    outline: none;
    flex-shrink: 0;
    border: 1px solid black;
}

#contact-detail-email {
  margin-bottom: 10px;
}

#contact-detail-container {
  font-size: 25px;
  margin-left: 2px;
  font-weight: bold;
  flex: 1;
  /* padding: 150px 150px 150px 140px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;  
  text-align: center;
}

#contact-header {
  font-weight: lighter;
}

#contact-form-container {
  /* padding: 200px; */
}

#contact-button {
  justify-content: center;
    background-color: black;
    color: white;
    padding: 10px 35px 10px 35px;
    font: 16px;
    font-family: 'Poppins', sans-serif;
    border: 0.2px solid #cbc9c9;
    font-size: 12px;
    font-weight: 320;
    margin-top: 0;
    border-radius: 20px;
    transition: box-shadow 0.2s ease-int-out;
    flex-shrink: 0;
    margin-top: 15px;
}

#contact-button:hover {
  background-color: rgb(36, 35, 35);
  flex-shrink: 0;
  cursor: pointer;
}

#contact-message {
  height: 70px;
}

#contact-success {
    color: black;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-style: wavy;
    padding : 5px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
}

#contact-error {
    color: rgb(252, 30, 30);
    text-decoration: underline;
    text-underline-offset: 5px;
    padding : 5px;
    margin-top: 10px;
    font-size: 11px;
}


</style>